import React, { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getAuthToken, getUserDetails } from "../helpers/authentication";
import { Context } from "../helpers/Context";
import { getCurrentDomain, GetCartData, SetCartData } from "../helpers/Lookup";
import CartItem from "./CartItem";

const CheckoutPage = () => {
  const [couponCode, setCouponCode] = useState('');
  const navigate = useNavigate();
  const { cartItems, cartSubTotal, cartTotal, applyCoupon, couponError, appliedCoupon, getCart } = useContext(Context);


  const calculateTotal = (cartSubTotal, deliveryFee, coupon) => {
    let total = cartSubTotal;

    if (coupon) {
      if (coupon.type === 1) {
        total -= coupon.amount;
      } else if (coupon.type === 2) {
        total -= (total * coupon.amount) / 100;
      }
    }

    return total + deliveryFee;
  };

  const saveCart = async () => {

    let cart = GetCartData();

    if (!cart.userId)
      cart.userId = getUserDetails().id;

    var bearer = 'Bearer ' + getAuthToken();

    try {
      const response = await fetch('/api/cart', {
        method: cart.id === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(cart),
      });
      if (response.ok) {
        const cartId = await response.json();
        //console.log(cartId);
        //load user cart
        await SetCartData(getCart(getUserDetails().id));
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }


  }


  const handleCheckoutStep = async (event) => {
    event.stopPropagation();
    if (getAuthToken()) {
      await saveCart();
      navigate("/order-completion");
    }
    else
      alert("Please login or register to complete order");
  }

  return (
    <div className="">
      <nav className="mb-3 crumbs">
        <Link to="/" className="breadcrumb-active"><small>Home</small></Link>
        <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
        <small className="breadcrumb-not-active">Checkout</small>
      </nav>
      <div className="" ></div>
      <div className="cart-content">

        {!!cartItems?.length && (
          <><h1 className="mb-4">Checkout</h1>
            <div className="scrollable-cart">
              <CartItem />
            </div>
            <hr />
            <div className="">
              <div className="row d-flex flex-wrap justify-content-between mb-3">
                {<div className="coupon-button col-sm-12 col-md-4">
                  <div className="input-group mb-3">
                    <input
                      className="coupon-input"
                      type="text"
                      placeholder="Enter coupon code"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                    <button className="coupon-button" onClick={() => getAuthToken() ? applyCoupon(couponCode) : alert("Please login or register to apply code")}>Apply</button>
                  </div>
                  {couponError && <p className="coupon-error">{couponError}</p>}
                  {appliedCoupon && appliedCoupon?.id ? (
                    <div>
                      <p className="coupon-message">Coupon applied: {appliedCoupon.code}</p>
                      
                    </div>
                  ) : ""}
                </div>}
                <div className="subtotal col-sm-12 col-md-6">
                  <div className="d-flex justify-content-between">
                    <span className="text">Promo Discount: </span>
                    <span className="text total">
                      {appliedCoupon ? (
                        appliedCoupon.type === 1
                          ? appliedCoupon.amount.toCurrencyString()
                          : appliedCoupon.type === 2
                            ? `${appliedCoupon.amount} %`
                            : (0).toCurrencyString()
                      ) : (
                          (0).toCurrencyString()
                      )}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="text">Delivery Fee: </span>
                    <span className="text total">
                      {(65).toCurrencyString()}
                    </span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <span className="text">Subtotal:</span>
                    <span className="text total">
                      {cartSubTotal.toCurrencyString()}
                    </span>
                  </div>
                  <div className="d-flex fs-4 justify-content-between">
                    <strong className="text">Total:</strong>
                    <strong className="text total">
                      {calculateTotal(cartSubTotal, 65, appliedCoupon).toCurrencyString()}
                    </strong>
                  </div>
                </div>
              </div>
              <div className="d-flex text-end">
                <button className="checkout-btn" onClick={handleCheckoutStep} >Checkout &nbsp;<i className="fas fa-chevron-right ms-2"></i></button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CheckoutPage;