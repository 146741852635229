/* eslint-disable no-case-declarations */
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Inject, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { HtmlEditor, Image, Count, Link, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { useEffect, useState } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Label } from 'reactstrap';
import { getAuthToken } from '../../helpers/authentication';
import { CommandTemplate, ConfirmDialogStyles, SearchGridToolbar, SimpleToolbarSettings } from '../../helpers/Global';
import { fieldRequired } from '../../helpers/validation';
import { BannerThumbnails } from '../BannerThumbnails';

export default function RazerBanners(props) {

  const [editData, setEditData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [editId, setEditId] = useState(0);

  const [title, setTitle] = useState('');
  const [filename, setFilename] = useState('');


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [actionType, setActionType] = useState('');
  const [actionData, setActionData] = useState('');
  const [displayOrder, setDisplayOrder] = useState(0);
  const [addedBy, setAddedBy] = useState(0);
  const [dateAdded, setDateAdded] = useState(new Date());
  const [modifiedBy, setModifiedBy] = useState(0);
  const [dateModified, setDateModified] = useState(new Date());
  const [status, setStatus] = useState(0);

  const [actionClass, setActionClass] = useState('col-md-8 mb-3');

  const [editModal, setEditModal] = useState(false);
  const [orderModal, setOrderModal] = useState(false);

  const toggle = () => setEditModal(!editModal);
  const toggleOrder = () => setOrderModal(!orderModal);

  const linkTypes = [
    { value: '0', text: '- None -' },
    { value: '1', text: 'Category' },
  ];



  const commandClick = async (args) => {
    switch (args.commandColumn.type) {
      case 'Delete':
        if (await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles)) {
          deleteItem(args.rowData.id);
        }
        break;
      default: //edit
        editItem(args.rowData.id);
        break;
    }
  }

  const loadData = async () => {
    const razerCategories = [13, 12, 11, 3, 17, 9, 1, 21];
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('/api/razerbanners', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setEditData(data);
      }
      else {
        console.log("Loading error - " + response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('/api/productcategories', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setCategoryData(data.filter(category =>
          razerCategories.includes(category.id)
        ));
      }
      else {
        console.log("Loading error - " + response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  const addItem = () => { editItem(0); }

  const editItem = (id) => {
    if (id > 0) {
      const data = editData.find((item) => { return item.id === id });
      setEditId(data.id);
      
      setTitle(data.title);
      setFilename(data.filename);
      setStartDate(data.startDate);
      setEndDate(data.endDate);
      if (data.actionType === '')
        setActionType('0');
      else
        setActionType(data.actionType);
      setActionData(data.actionData);
      setDisplayOrder(data.displayOrder);
      setAddedBy(data.addedBy);
      setDateAdded(data.dateAdded);
      setModifiedBy(data.modifiedBy);
      setDateModified(data.dateModified);
      setStatus(data.status);

    }
    else {
      //clear state fields
      setEditId(0);

      setTitle('');
      setFilename('');
      setStartDate(new Date());
      setEndDate(new Date());
      setActionType('0');
      setActionData('');
      setDisplayOrder(0);
      setAddedBy(0);
      setDateAdded(new Date());
      setModifiedBy(0);
      setDateModified(new Date());
      setStatus(0);

    }
    setEditModal(true);
    //console.log(categoryData);
  };

  const validateForm = () => {
    var valid = true;
    valid &= fieldRequired(title, 'tbTitleError', '* required');
    return valid;
  }

  const saveItem = async (event) => {
    event.stopPropagation();

    if (validateForm()) {
      const data = { id: editId, title: title, filename: filename, actionType: actionType, actionData: actionData };
      console.log(data);
      var bearer = 'Bearer ' + getAuthToken();

      //console.log(data);

      try {
        const response = await fetch('/api/razerbanners', {
          method: editId === 0 ? 'POST' : 'PUT',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),

        });
        if (response.ok) {
          await response.json();
          loadData();
          ToastUtility.show({
            title: 'Razer Banners', content: 'The razer banners was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
          });
        }
        else {
          console.log(response.status + ": " + response.statusText);
          if (response.status === 401)
            window.location.href = "/login";
        }

      } catch (e) {
        console.error(e);
        ToastUtility.show({
          title: 'Razer Banners', content: 'There was an error saving the razer banners!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
      toggle();
    }
  }

  const deleteItem = async (dataId) => {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('/api/razerbanners/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        loadData();
        ToastUtility.show({
          title: 'Razer Banners', content: 'The razer banners was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Razer Banners', content: 'There was an error deleting the razer banners!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  const onItemDrop = (e) => {
    const orderedData = [];
    e.source.currentData.map((item, idx) =>
      orderedData.push({ ...item, displayOrder: (idx + 1) })
    );
    setEditData(orderedData);
  }

  const saveOrder = async () => {

    toggleOrder();


    var bearer = 'Bearer ' + getAuthToken();

    try {
      const response = await fetch('/api/razerbanners/savedisplayorder', {
        method: 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(editData),
      });

      if (response.ok) {
        await response.json();
        loadData();
        ToastUtility.show({
          title: 'Razer Banners', content: 'The banner order was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
      //this.setState({ loading: false });
      ToastUtility.show({
        title: 'Razer Banners', content: 'There was an error saving the banner order!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }


  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (actionType === '1')
      setActionClass('col-md-8 mb-3')
    else
      setActionClass('d-none');
  }, [actionType]);

  
  return (
    <>
      <div className="row">
        <div className="col-md-4 "><h1>Razer Banners</h1></div>
        <div className="col-md-8 text-end align-self-center">
          <a href="/admin/administration" className="btn btn-sm btn-dark me-2"><i className="fas fa-chevron-left mr-2"></i> Back To Admin</a>
          <Button color="primary" size="sm" onClick={toggleOrder} className='mx-2'>Set Display Order <i className="fa-solid fa-sort ms-2"></i></Button>
          <Button color="primary" size="sm" onClick={addItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
        </div>
      </div>

      <GridComponent dataSource={editData} allowSorting={true} toolbar={SearchGridToolbar} commandClick={commandClick} >
        <ColumnsDirective>
          <ColumnDirective field='Title' width='100' headerText="Title" />
          <ColumnDirective headerText='Actions' width='25' commands={CommandTemplate} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>

      <Modal isOpen={editModal} toggle={toggle} scrollable size="lg" backdrop="static">
        <ModalHeader toggle={toggle}>Edit Razer Banners</ModalHeader>
        <ModalBody>
          <div className='mb-3'>
            <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='350' floatLabelType='Auto' showClearButton={true} value={title} onChange={e => setTitle(e.value)} />
            <div id='tbTitleError' className='error-message' />
          </div>
          <div className="row">
            <div className="col-md-4 mb-3">
              <DropDownListComponent id='tbActionType' name='tbActionType' placeholder='Link Type' dataSource={linkTypes} fields={{ text: 'text', value: 'value' }} floatLabelType='Always' value={actionType} change={e => setActionType(e.value)} />
            </div>
            <div className={actionClass}>
              <DropDownListComponent id='tbCategory' name='tbCategory' placeholder='Category' dataSource={categoryData} fields={{ text: 'title', value: 'slug' }} floatLabelType='Always' value={actionData} change={e => setActionData(e.value)} />
            </div>
          </div>

          <div className='mb-3'>
            <Label>Home Banner (best fit 1440 x 375)</Label>
            <BannerThumbnails bannerUrls={ filename } isSingleBanner={true} onUpdateBannerUrls={(returned) => setFilename(returned[0])} /><div id='tbFilenameError' className='error-message' />
          </div>
          
        </ModalBody>
        <ModalFooter>
          <Button color="dark" size="sm" onClick={toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
          <Button color="success" size="sm" onClick={saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={orderModal} toggle={toggleOrder} scrollable backdrop="static">
        <ModalHeader >Set Display Order</ModalHeader>
        <ModalBody>
          <i style={{ color: "grey", fontSize: 12 }}>Drag and Drop</i>
          <ListBoxComponent dataSource={editData} allowDragAndDrop={true} fields={{ text: "title", value: "id" }} drop={onItemDrop} />
        </ModalBody>
        <ModalFooter>
          <Button color="dark" size="sm" onClick={toggleOrder}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
          <Button color="success" size="sm" onClick={saveOrder}>Save <i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>


    </>
  );
}

